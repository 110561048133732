<template>
  <div class="model page">
    <section class="section">
      <h1 class="title">guide</h1>

      <span v-if="option_drive">
        <h2>Photoshop auto export</h2>

        <p>
          Everytime you save your Photoshop document you can export a copy of
          your work automatically to your Google Drive and see the result on the
          model.
        </p>

        <h2>iPad</h2>

        <p>Save to Google Drive from your iPad graphic application.</p>
      </span>
      <span v-else> video </span>

      <abstraktion-footer />
    </section>
  </div>
</template>

<script>
import AbstraktionFooter from "../components/AbstraktionFooter.vue";
export default {
  components: { AbstraktionFooter },
};
</script>

<style lang="scss">
@import "../scss/main.scss";
</style>